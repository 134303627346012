@import 'assets/scss/custom/_variables.scss';
:root {
  --progress-bar-color: #fff;
}

.barWrapper:nth-last-child(1) {
  .bar {
    border-radius: 0 8.14286px 8.14286px 0 !important;
  }
}
.barWrapper:nth-child(1) {
  .bar {
    border-radius: 8.14286px 0px 0px 8.14286px !important;
  }
}
.barWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #8898aa;
    word-break: break-all;
    &.active {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #32325d;
    }
  }

  .bar {
    width: 100%;
    overflow: hidden;

    border: 1px solid #d2e3ee;
    margin-bottom: 0;
    box-shadow: none;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.75);

    :global {
      .progress {
        height: 16px;
        background-color: white;
        border-radius: 0;
      }
      .progress-bar {
        background-color: rgba(55, 213, 242, 0.17);
        border-radius: 0;
        background: repeating-linear-gradient(
          135deg,
          #0da5c0,
          #0da5c0 1px,
          #37d5f2 2px,
          #37d5f2 5px
        );
      }
    }

    &.active {
      :global {
        .progress {
          height: 16px;
          background-color: white !important;
          border-radius: 0;
        }
        .progress-bar {
          height: 16px;
          background-color: var(--progress-bar-color) !important;
          background: none;
          border-radius: 0;
        }
      }
    }
  }
}
