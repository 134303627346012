@import 'assets/scss/custom/_variables.scss';

.root {
  width: 100%;

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
  }

  &.fullscreen {
    padding-top: 20px;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 1px;
    left: 1px;
    z-index: $zindex-modal;
    background: $body-bg;

    .content {
      height: calc(100vh - 12rem);
      max-height: calc(100vh - 190px);
      min-height: calc(100vh - 190px);
    }

    .addColumn {
      max-height: calc(100vh - 250px) !important;
    }
  }

  .expand {
    color: #32325d;
    cursor: pointer;
  }
  .header {
    font-size: 14px;
    font-weight: normal;

    .subText {
      font-size: 14px;
      font-weight: 300;
      @media (max-width: 375.98px) {
        display: block;
      }
    }

    .searchBox {
      height: 30px;
      max-width: 250px;
      margin-left: 7px;
    }

    .statis {
      font-size: 14px;
      font-weight: 300;
      margin-right: 20px;

      b {
        font-weight: 600;
        font-size: 18px;
        margin-right: 5px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .content {
    white-space: nowrap;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 350px);
    min-height: calc(100vh - 350px);

    .column {
      background: $body-bg;
      margin-right: 20px;
      width: 20rem;
      flex: 0 0 20rem;
      display: flex;
      flex-direction: column;
      .columnNameInput {
        height: 2rem;
      }
      .badgeWrapper {
        margin-right: 0 !important;
        margin-left: auto !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
      .statusBadge {
        color: $white;
        margin-left: auto;
      }
      &.addColumn {
        font-size: 5.5rem;
        color: $gray-300;
        border: 3px dashed $gray-300;
        border-radius: 0.375rem;
        margin-top: 2.75rem;
        margin-right: 0px;
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 410px);
        cursor: pointer;
        .columnHeading {
          color: transparent;
        }
        &:hover,
        &:active {
          border: none;
          color: $white;
          background-image: url('../../../../assets/img/theme/Add_Column_Kanban_Bg.svg');
          background-size: cover;
          background-repeat: no-repeat;
          .columnHeading {
            color: inherit;
          }
        }
      }
      @media (max-width: 1023.98px) {
        width: 20rem;
      }

      .list {
        width: 100%;
        height: 100%;
        @media (max-width: 1023.98px) {
          width: 320px;
        }
        padding: 10px 10px 0 10px;
        margin-top: 10px;
        background: $white;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        // overflow-y: auto;
        // height: calc(100vh - 24.5rem);
        > div {
          min-height: 100%;
        }
      }
    }
  }
}

.grabHand {
  cursor: grab;
}

.pointerHand {
  cursor: pointer;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
