.root {
  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .clientBreadCrumbs {
    :global {
      .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #757190;
        content: '/';
      }
    }
    .breadcrumbs {
      ol {
        padding: 0;
        margin-bottom: 0;
        background-color: transparent;
        display: flex;
        align-items: center;
      }
      .breadcrumb {
        text-transform: capitalize;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #757190;
        display: flex;
        align-items: center;
        &.main {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 28px;
          color: #322d52;
        }
      }
    }
  }

  .rotate180 {
    transform: rotate(180deg);
  }
}
.navBar {
  position: sticky;
  top: 0;
  z-index: 9;
}
.notificationDropdown {
  max-width: 420px;
  @media (max-width: 480px) {
    width: calc(100vw - 10px) !important;
    min-width: unset;
  }
}
.dropdown-link {
  color: rgba(0, 0, 0, 0.9);

  &:hover {
    color: rgba(0, 0, 0, 0.9);
  }
}

.clientEstimate {
  position: absolute;
  top: 32px;
  left: 30px;

  right: 0;
  z-index: 15;
  background: #1b9763;
  box-shadow: 0px 4px 27px rgba(27, 151, 99, 0.51);
  border-radius: 11px;
  padding: 22px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 788px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: baseline;
    left: 12px;
    right: 12px;
  }
  .norm {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #ffffff;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  }
  .bold {
    font-weight: 700;
    font-size: 44px;
    line-height: 60px;
    color: #ffffff;
    text-shadow: 0px 1px 4px rgba(19, 125, 81, 0.61);
  }
}

.impersonateBar {
  font-family: Open Sans;
  font-size: 18px;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .norm {
    margin-right: 8px;
  }
  .bold {
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;

    margin-right: 24px;
  }

  .exitBtn {
    display: block;
    min-height: 44px;
    background: #2dce89;
    /* Input Form Controls/Default */
    box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
    border-radius: 4px;
    padding: 12px 18px;

    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;

    cursor: pointer;
  }
}

.headerBadge {
  width: 26px;
  height: 26px;
  display: inline-flex;
  margin-left: 11px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #ec0c38;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}
