.wrapper {
  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      color: #32325d;

      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px; /* 168.75% */
    }
    .tableSubHeader {
      color: #adb5bd;

      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .cardBody {
    padding: 0;
    max-height: 315px;
    overflow: auto;
    .tableWrapper {
      overflow: visible;
      min-height: 10.5rem;
      table {
        // table-layout: fixed;
        * {
          td {
            white-space: nowrap !important;
            max-width: 7.75rem;
            vertical-align: middle;
            padding-top: 24px;
            padding-bottom: 24px;
          }
        }
        .activeName {
          background: #f2fbff;
        }
        .name {
          font-weight: 600;
          // &.active {
          //   background: #f2fbff;
          // }
          span {
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        tbody {
        }
        .row {
          cursor: pointer;
        }
        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
