@import 'assets/scss/custom/_variables.scss';

.container {
  display: inline-flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  border-radius: 6px;
  border: 1px solid #d2e3ee;
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(136, 152, 170, 0.15);

  .title {
    color: #172b4d;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.14px;
  }

  .value {
    color: #3c4d69;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
  }
}
