.dataContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header {
    color: #8899a9;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: bold !important;

    :global {
      body {
        font-weight: 700 !important;
      }
    }
  }

  .description {
    color: var(--color-black-95, rgba(0, 0, 0, 0.95));
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
}
.wrapper {
  max-height: 600px;
  overflow-y: auto;
}

.headText {
  display: flex;
  justify-content: space-between;
  color: #32325d;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
  a {
    margin-right: 4px;
  }
}

.container {
  flex-shrink: 0;
  border-radius: 5px;
  background: #fff;
  padding: 23px 0px 0px 32px;
}

.collabratorContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: 'flex-start';
  margin-bottom: 15px;
}

.collabItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 26px;
  height: 26px;
}

.collabratorHeader {
  color: var(--color-black-95, rgba(0, 0, 0, 0.95));
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.collabratorName {
  color: var(--color-black-95, rgba(0, 0, 0, 0.95));
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.iconWrapper {
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overviewWrapper {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'flex-start';
  gap: '24px';
}
.icon {
  height: 14px;
}
