@import 'assets/scss/custom/_variables.scss';
.modal {
  .title {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
    h3 {
      color: #32325d;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      white-space: nowrap;
      margin-bottom: 0;
    }
    .divider {
      height: 1px;
      width: 100%;
      background: #dee2e6;
    }
  }
  .filterContentWrapper {
    border-radius: 4px;
    border: 1px solid #dee2e6;
    background: #fff;
    padding: 8px;

    // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08),
    //   0px 4px 6px 0px rgba(50, 50, 93, 0.11);
  }
  .modalBody {
    overflow-x: auto;
    .filterWrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 8px;
      .checkboxFilters {
        display: grid;
        grid-template-columns: 5fr 8fr;
        gap: 8px;
        @media (max-width: 1000px) {
          grid-template-columns: 1fr;
        }
        .projectDirectors {
          max-height: 325px;
          overflow: auto;

          &::-webkit-scrollbar {
            background-color: transparent;
            opacity: 0.9;
            width: 4px;
            border-radius: 6px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 40px;
            width: 4px;
          }

          .options {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
        }
        .projectStaff {
          max-height: 325px;
          overflow: auto;

          &::-webkit-scrollbar {
            background-color: transparent;
            opacity: 0.9;
            width: 4px;
            border-radius: 6px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 40px;
            width: 4px;
          }
          .options {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
          }
        }
      }
      .dateFilters {
        display: grid;
        grid-template-columns: 5fr 8fr;
        gap: 8px;
        @media (max-width: 1000px) {
          grid-template-columns: 1fr;
        }

        .datePicker {
          position: relative;
          .resetButton {
            button {
              padding-top: 0;
              padding-bottom: 0;
            }
          }
          .rootStyle {
            :global {
              .CalendarDay {
                font-size: 0.875rem;
              }
              .CalendarMonth {
                padding: 0 !important;
              }
              .DayPicker__withBorder {
                border: none;
                box-shadow: none;
              }
            }

            .calendarStyle {
              position: relative;
              left: 0;
              top: 0;
              border: none;
              box-shadow: none;
              z-index: 1;
              .leftPanelStyle {
                width: fit-content;
                .buttonStyle {
                  padding: 7px;
                  color: #525f7f;
                  font-family: Open Sans;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px; /* 166.667% */
                  letter-spacing: -0.18px;
                  white-space: nowrap;
                }
                :global {
                  .active {
                    background-color: $primary;
                    color: $white !important;
                  }
                }
              }
            }
          }
        }

        .signedElDate {
        }
        .dateSideWrapper {
          display: grid;
          grid-template-columns: 8fr 5fr;
          gap: 8px;
          @media (max-width: 1000px) {
            grid-template-columns: 1fr;
          }
          .estimatedDeliveryDate {
          }
          .clientDeadlineDate {
          }
        }
      }
    }
  }
  .modalFooter {
    .buttons {
    }
  }
}
