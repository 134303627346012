.container {
  margin-top: 24px;
  .headerWrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .header {
      margin-bottom: 48px;
      display: flex;
      gap: 8px;
      align-items: center;

      .leftIcon {
        font-size: 26px;
        cursor: pointer;
      }

      .studyName {
        color: #32325d;
        font-family: Open Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .rightStudyName {
        color: #32325d;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .breadcrumb {
        margin-top: 6px;
        color: #8898aa;
      }

      h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 35px;
        color: #32325d;
      }
    }
    .goToClient {
      padding: 5px 10px;
      color: #172b4d;
      text-align: center;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.32px;
    }
  }

  .card {
    background: #f7f8fd;
    box-shadow: none;

    .cardBody {
      background: #f7f8fd;
      box-shadow: none;
      padding: 0;
      .row {
        @media (max-width: 991px) {
          gap: 30px;
        }
        .content {
        }
        .tab {
          position: sticky;
          top: 100px;
          align-self: start;
          overflow-x: auto;
          height: 100%;
          @media (max-width: 991px) {
            position: relative;
            top: 0;
          }
          .kickoffAction {
            display: flex;
            margin-bottom: 20px;
            padding: 13px 30px 21px 29px;
            flex-direction: column;
            align-items: flex-start;
            gap: 13px;
            border-radius: 4px;
            background: #fff;
            h3 {
              color: #32325d;
              font-family: Open Sans;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              margin-bottom: 0;
            }
            .button {
              padding: 8px;
              width: 100%;
              color: #fff;
              text-align: center;
              font-family: Open Sans;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }
        }
      }
      .expenseCardWrapper {
        margin-bottom: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 8px;
        @media (max-width: 1400px) {
          grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 991px) {
          grid-template-columns: 1fr;
        }
      }
      .contentWrapper {
        background-color: #fff;
        border-radius: 6px;
        .tabs {
          padding-left: 24px;
          padding-top: 8px;
          gap: 40px;
          margin-bottom: 16px;
          span {
            font-size: 20px;
          }
        }
        .content {
          padding: 8px 12px;
        }
      }
    }

    .tab {
      font-family: Open Sans;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      flex: 1;
      height: 56px;
      flex-shrink: 0;
      background: 'red';
      box-shadow: none;
      background: none;
      color: black;
      @media (max-width: 768px) {
        font-size: 16px;
        height: 40px;
      }
    }
    .tabWrapper {
      padding-left: 0px !important;
      padding-right: 0px !important;
      background: #fff;
      // box-shadow: 0px 0px 32px 0px rgba(136, 152, 170, 0.15);
    }
    .expandCardWrapper {
      display: 'flex';
      justify-content: 'space-between';
    }
    .inactive {
      font-weight: 400;
    }
    .headerBadge {
      margin-left: 8px;
      font-size: 12px !important;
      width: 20px;
      height: 20px;
      display: inline-flex;
      aspect-ratio: 1/1;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #f75676;
      color: #fff;
    }

    .active {
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    }
  }
}
