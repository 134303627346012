.cards {
  display: flex;
  max-height: 600px;

  :global {
    .card-body {
      padding: 0;
    }
  }

  .agreementsCardBody {
    margin: 0;

    .engagementsWrapper {
      padding: 25px;
      border-bottom: 1px solid #e9ecef;
      .engagementsTitles {
        font-size: 14px;
        font-weight: bold;
        margin-left: 8px;
      }
    }
  }
}

.card {
  max-height: calc(100vh - 318px) !important;
}
