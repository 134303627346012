@import 'assets/scss/custom/_variables.scss';
.modalWrapper {
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
  }
  .header {
    border-bottom: 1px solid #e6e6e6;

    :global {
      .modal-title {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        color: #32325d;
        font-weight: 400;
        font-size: 17px;

        .leftHeader {
          display: flex;
          align-items: center;
          order: 1;
          .title {
            font-weight: bold;
            margin-right: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 270px;
          }

          .status {
            background: #f0ea53;
            border-radius: 12px;
            font-weight: 600;
            font-size: 10px;
            color: #000000;
            margin-left: 15px;
            display: flex;
            align-items: center;
            padding: 5px 12px;
          }
        }

        .rightHeader {
          order: 2;
          margin-left: auto;
          display: flex;
          align-items: center;
          .time {
            margin-left: auto;
          }

          @media (max-width: 991px) {
            order: -1;
            margin-bottom: 10px;
          }

          button {
            border: none;
            background: none;
            outline: none;
            display: flex;
            align-items: center;
            margin-left: 15px;
          }
        }
      }
    }

    &.tabs {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;

      @media (max-width: 991px) {
        width: 100%;
        justify-content: space-around;
      }

      li {
        text-align: center;
        cursor: pointer;
        margin-right: 40px;
        font-size: 14px;
        color: #a2a0b4;

        &.active {
          color: #53c3f0;

          img[action='tab'] {
            filter: invert(72%) sepia(86%) saturate(1437%) hue-rotate(167deg)
              brightness(97%) contrast(94%);
          }
        }

        img {
          margin-bottom: 5px;
        }

        @media (max-width: 991px) {
          div {
            display: none;
          }
        }
      }
    }
  }

  .footer {
    border-top: 1px solid #e6e6e6;

    .deny {
      background: #dee2e6;
      border: #dee2e6;
      color: #525f7f;
    }

    button {
      box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
    }

    .signed {
      width: 100%;
      color: #525f7f;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &.resend {
      display: flex;
      justify-content: space-between;

      .resentButton {
        border: 1px solid #525f7f;
        filter: drop-shadow(0px 3px 2px rgba(233, 236, 239, 0.05));
        border-radius: 4px;
      }

      .voidButton {
        background: #dee2e6;
      }
    }
  }

  .chatBox {
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-top: 10px;
    padding-bottom: 5px;

    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: #998ad4;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .box {
      flex-grow: 1;
      textarea {
        resize: none;
        width: 100%;
        border: 1px solid #e9ecef;
        border-radius: 4px;
        padding: 12px;
        font-size: 15px;
        padding-right: 50px;
        height: 50px;

        &::placeholder {
          color: #adb5bd;
        }
      }
    }

    button {
      border: none;
      background: none;
      position: absolute;
      right: 30px;
      bottom: 20px;
    }
  }
}
