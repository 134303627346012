.earnings {
  color: #32325d;
  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    margin-top: 20px;
    margin-bottom: 0px;
    color: #000000;
  }

  .infoWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    .boxInfo {
      min-height: 74px;
      // width: 265px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-right: 24px;
      margin-top: 24px;
      padding: 19px 16px;
      @media screen and (max-width: 583px) {
        width: 100%;
        margin-right: 0;
      }
      background: #ffffff;
      border-radius: 6px;
      filter: drop-shadow(0px 1px 2px rgba(50, 50, 93, 0.1))
        drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
      p {
        margin: 0;
      }
      p:first-child {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        /* or 129% */
        letter-spacing: -0.01em;
      }
      p + p {
        font-weight: 700;
        font-size: 28px;
        line-height: 38px;
        text-align: right;
        letter-spacing: -0.02em;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .chartWrapper {
    background: #ffffff;
    border-radius: 6px;
    filter: drop-shadow(0px 1px 2px rgba(50, 50, 93, 0.1))
      drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));

    margin-top: 24px;
    padding: 22px 30px;
    margin-bottom: 32px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 24px;
      padding: 22px 5px;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height, or 129% */
      letter-spacing: -0.01em;
      margin-bottom: 20px;
    }

    .barChart {
      height: 200px;
      canvas {
        width: 70% !important;
        padding-bottom: 20px;
        @media screen and (max-width: 600px) {
          width: 100% !important;
        }
      }
      margin-bottom: 15px;
    }

    .pieChart {
      padding: 22px 24px;
      margin-bottom: 0;
      box-shadow: none;

      height: 150px;
      width: 100%;
      position: relative;

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #32325d;
      }

      .chart-title {
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 14px;
      }

      span {
        position: absolute;
        top: 0;
        right: -40px;
      }
      canvas {
        margin: auto;
        @media screen and (max-width: 343px) {
          width: 90% !important;
        }
      }
      @media screen and (max-width: 600px) {
        width: 100% !important;
      }
    }
  }

  .empty {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 6px;
    filter: drop-shadow(0px 1px 2px rgba(50, 50, 93, 0.1))
      drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));

    margin-top: 24px;
    padding: 2px 30px;
    margin-bottom: 32px;
    position: relative;

    span {
      position: absolute;
      top: 50%;
      z-index: 10;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #bdbdbd;
    }
  }

  .tableView {
    margin-top: 16px;
    .tableWrapper {
      overflow-x: auto;
      border-radius: 6px;
      max-height: 300px;
      overflow-y: scroll;
    }
    table {
      border-bottom: 0px !important;
      * {
        td {
          vertical-align: middle;
          padding-right: 0.5rem;
        }
      }
      thead {
        border-left: 0px solid #e8e8e8;
        height: 44px;
        width: calc(100% - 1em);
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
        tr {
          display: table;
          width: 100%;
          table-layout: fixed;
          th {
            padding: 14.5px 20px;
            background-color: #e8e8e8;
            font-weight: 700;
            font-size: 12px;
            color: #000000;
            text-align: left;
          }
        }
      }
      tfoot {
        border-left: 0px solid #e8e8e8;
        height: 44px;
        width: calc(100% - 1em);
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        tr {
          display: table;
          width: 100%;
          table-layout: fixed;
          th {
            padding: 12.5px 20px;
            text-align: left;
            font-weight: 700;
            font-size: 14px;
            line-height: 30px;
            background-color: #e8e8e8;
            color: #000000;
          }
        }
      }
      tbody {
        tr {
          display: table;
          width: 100%;
          table-layout: fixed;
          td {
            padding: 8.5px 20px;
            text-align: left;
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
          }
        }
      }
      .row {
        cursor: pointer;
      }
      .addIcon {
        color: #bdbdbd;
        cursor: pointer;
      }
      .infoIconContainer {
        position: relative;
        left: 20%;
        right: 20%;
      }
    }

    .caret {
      span {
        margin-left: 10px;
      }
      i {
        color: white;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        width: 30px;
        justify-content: center;
        background: #32325d;
      }
    }

    .paymentWrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin-left: 4px;
        position: relative;
        width: 200px;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }
    }

    .tooltipCustom {
      div {
        display: flex;
        justify-content: space-between;
      }
      hr {
        margin: 0;
      }
    }
  }
  .historyTable {
    max-width: 600px;
  }

  .iconColor {
    color: #525f7f;
  }
}

.noDataWrapper {
  border-radius: 6px;
  overflow: hidden;
}

.emptyStateHeader {
  height: 47px;
  background: #e8e8e8;
  width: 100%;
  margin-bottom: 20px;
}

.noData {
  margin-bottom: 10px;
  .iconWrapper {
    background: #e8e8e866;
    // padding: 16px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 25px;
    }
  }
}
.defaultText {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #cdcdcd;
  margin-bottom: 20px;
}

.tooltipCustom {
  :global {
    .tooltip.show {
      opacity: 1;
    }
    .tooltip-inner {
      max-width: 331px !important;
      padding: 16px;
    }
  }
  .tooltipPoints {
    display: flex;
    align-items: baseline;
  }

  .hide {
    display: none !important;
  }

  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 10px;
    text-align: left;
    .bold {
      font-weight: 600;
    }
  }
}
