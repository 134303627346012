@import 'assets/scss/custom/_variables.scss';
.headerDropdowns {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 32px;
  flex-wrap: wrap;
  div {
    p {
      font-family: Open Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 8px;
      color: #32325d;
      text-transform: uppercase;
    }
  }
  .dateRangePicker {
    :global {
      .form-control {
        border-color: #32325d;
      }
      .input-group-text {
        border-color: #32325d;
      }
      button {
        border: none;
        color: #525f7f !important;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &.active {
          background: #32325d !important;
          color: #ffffff !important;
        }
      }
      button:first-child {
        background-color: inherit;
        color: #525f7f;
      }

      .CalendarDay {
        font-size: 0.875rem;
      }

      .CalendarDay__selected,
      .CalendarDay__selected:active,
      .CalendarDay__selected:hover {
        background: $primary;
        border-color: $primary;
      }

      .CalendarDay__selected_span {
        background: rgba(50, 50, 93, 0.3);
        border-color: rgba(50, 50, 93, 0.3);
        color: $white;
        border-radius: 0px !important;
      }

      .CalendarDay__hovered_span,
      .CalendarDay__hovered_span:hover {
        background: rgba(50, 50, 93, 0.3);
        border-color: rgba(50, 50, 93, 0.3);
        color: #525f7f;
      }

      .DayPickerKeyboardShortcuts_show {
        display: none;
      }

      .CalendarDay__default {
        color: #525f7f;
        border: none;
      }

      .CalendarDay__default:hover {
        border-radius: 50%;
      }

      .CalendarDay__outside {
        color: #adb5bd;
      }

      .DayPicker_weekHeader_li small {
        font-size: 14px;
        color: #525f7f;
        font-weight: normal;
      }

      .CalendarDay__selected,
      .CalendarDay__selected:hover {
        background: none !important;
        color: #fff !important;
        padding: 0px;
        position: relative;
        .startDate,
        .endDate {
          background-color: #32325d !important;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          z-index: 10;
          position: absolute;
          top: 0;
          left: 0;
        }

        .rightBG {
          position: absolute;
          right: 0;
          top: 0;
          width: 50%;
          height: 100%;
          background: rgba(50, 50, 93, 0.3);
          z-index: 1;
        }

        .leftBG {
          position: absolute;
          left: 0;
          top: 0;
          width: 50%;
          height: 100%;
          background: rgba(50, 50, 93, 0.3);
          z-index: 1;
        }
      }

      .CalendarMonth_table {
        margin-top: 15px;
      }
    }
  }

  .customDropdown {
    :global {
      .dropdown-menu {
        z-index: 999999;
      }
      .dropdown-item {
        color: #525f7f;
      }
      .dropdown-item.active {
        font-weight: 600;
        color: #525f7f;
      }
    }
    button {
      margin-left: 0 !important;
      border: 1px solid #32325d !important;
      background-color: white !important;
      color: black !important;
    }
  }
  .caret {
    span {
      margin-left: 10px;
      color: #32325d;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      margin-right: 35px;
    }
    .statusText {
      color: #525f7f;
    }
    i {
      color: white;
      position: absolute;
      right: -1px;
      top: -1px;
      height: 102%;
      display: flex;
      align-items: center;
      width: 30px;
      justify-content: center;
      background: #32325d;
      border-radius: 0px 4px 4px 0px;
    }
  }
  div {
    button:first-child {
      margin-right: 1rem;
      background: #0ca8eb;
      color: #ffffff;
      border: none;
      min-width: 155px;
      text-align: inherit;

      @media screen and (max-width: 490px) {
        margin-bottom: 1rem;
      }
    }

    button {
      cursor: pointer;
      width: fit-content;
      padding: 13px 0;
      height: 48px;
      border: 1px solid #525f7f;
      /* Input Form Controls/Default */
      filter: drop-shadow(0px 3px 2px rgba(233, 236, 239, 0.05));
      border-radius: 4px;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;

      background-color: transparent;
    }
    .searchButtonWrapper {
      display: flex;
      align-items: center;
      gap: 10px;

      .input {
        border-radius: 4px;
        border: 1px solid #32325d;
        background: #fff;
      }
    }
  }
}
