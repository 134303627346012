@import 'assets/scss/custom/_variables.scss';

.actionBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  flex: 1 1;
  margin: 0 0.5rem;
  cursor: pointer;
  @media (max-width: 468px) {
    flex: 1 1 40%;
    margin: 0.5rem !important;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  .box {
    background: $white;
    position: relative;
    height: calc(100% - 2px) !important;
    width: calc(100% - 2px) !important;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    @media (max-width: 468px) {
      padding: 2rem 1rem;
    }
    h2 {
      margin-top: 0.5rem;
      margin-bottom: 0;
      color: #4f4f4f;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
    }
  }

  .createProject {
    border: 1px solid $success;
  }

  .createClient {
    border: 1px solid $primary;
  }
  .users {
    color: #9b51e0;
    font-size: 23px;
  }
  .infoDiv {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 1;
    width: 16px;
    transition: width 0.2s;
    cursor: pointer;
    .infoIcon {
      color: $lyght-blue;
      font-size: 14px;
      display: flex;
    }
    &.expand {
      opacity: 1;
      background: $lyght-blue;
      width: 60px;
      padding: 3px;
      border-radius: 15px;
      .infoIcon {
        font-size: 12px;
        color: $white;
      }
      .word1 {
        -webkit-animation: word1anim 5s infinite;
        animation: word1anim 5s infinite;
        transform-style: preserve-3d;
      }

      .word2 {
        -webkit-animation: word2anim 5s infinite;
        animation: word2anim 5s infinite;
        transform-style: preserve-3d;
      }

      .word3 {
        -webkit-animation: word3anim 5s infinite;
        animation: word3anim 5s infinite;
        transform-style: preserve-3d;
      }
    }
  }
  .gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;

    &.scheduleMeeting {
      background: linear-gradient(90deg, #2dce89 0%, #2dcecc 100%);
    }
    &.viewDocuments {
      background: linear-gradient(90deg, #fb6340 0%, #fbb140 100%);
    }
    &.inviteTeammate {
      background: linear-gradient(90deg, #8965e0 0%, #bc65e0 100%);
    }
    &.createStory {
      background: linear-gradient(
        270deg,
        rgba(242, 122, 54, 1) 0%,
        rgba(255, 209, 120, 1) 100%
      );
    }
    &.inviteMember {
      background: linear-gradient(
        270deg,
        rgb(148, 42, 253) 0%,
        rgb(236, 34, 204) 100%
      );
    }
    &.createEpic {
      background: linear-gradient(
        270deg,
        rgb(94, 114, 228) 0%,
        rgb(130, 94, 228) 100%
      );
    }
    &.viewBacklog {
      background: linear-gradient(
        270deg,
        rgb(0, 176, 240) 0%,
        rgb(38, 151, 223) 100%
      );
    }
  }
}

.word {
  font-weight: bold;
  opacity: 0;
  position: absolute;
  top: 1px;
  left: 4px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  color: $white;
  overflow: hidden;
  animation: animate 6s linear infinite 0s;
}

@keyframes word1anim {
  0% {
    opacity: 0;
    transform: rotateY(90deg);
  }
  16.5% {
    opacity: 1;
    transform: rotateY(0deg);
  }
  33% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
}

@keyframes word2anim {
  33% {
    opacity: 0;
    transform: rotateY(90deg);
  }
  49.5% {
    opacity: 1;
    transform: rotateY(0deg);
  }
  66% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
}

@keyframes word3anim {
  66% {
    opacity: 0;
    transform: rotateY(90deg);
  }
  83.5% {
    opacity: 1;
    transform: rotateY(0deg);
  }
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
}
