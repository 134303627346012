.expandDocTag {
  color: #525f7f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}
.card {
  box-shadow: none;
  padding: 4px;
  .container {
    display: flex;
    margin-bottom: 16px;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
    div {
      cursor: pointer;
    }
  }
  .documentsWrapper {
    overflow-x: auto;
  }

  .notificationList {
    padding: 0;
    margin-bottom: 26px;
    p {
      color: #32325d;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0;
    }
  }
}
